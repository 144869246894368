<template>
  <div class="spin" v-loading="true">跳转中...</div>
</template>

<script setup>
import { watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const router = useRouter()
const store = useStore()
const firstMenu = computed(() => store.getters['user/firstMenu'])

watch(
  firstMenu,
  (v) => {
    if (v) {
      router.replace(v)
    }
  },
  { immediate: true }
)
</script>

<style lang="less" scoped>
.spin {
  text-align: center;
  padding-top: 200px;
}
</style>
